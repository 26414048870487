import React from 'react'



export default function SomeWorksPage() {
    return (
        <div >
           
        </div>

    )
}