import Balloon from "../static/imagen/balloon.png";
import dino from "../static/imagen/dino.png";
import chicken from "../static/imagen/chicken.png";
import frog from "../static/imagen/frog.png";
import penalty from "../static/imagen/penalty.png";
import squidgame from "../static/imagen/squidgame.png";
// import Tortas7 from "../images/Tortas7.jpg";

const data = [

                {
                    "name": "balloon",
                    "image": `${Balloon}`,
                    "key":1
                },
                {
                    "name": "balloon",
                    "image": `${dino}`,
                    "key":2
                },
                {
                    "name": "balloon",
                    "image": `${chicken}`,
                    "key":3
                },
                {
                    "name": "balloon",
                    "image": `${frog}`,
                    "key":4
                },
                {
                    "name": "balloon",
                    "image": `${penalty}`,
                    "key":5
                },
                {
                    "name": "balloon",
                    "image": `${squidgame}`,
                    "key":6
                },
                
            ]

            export default  data
