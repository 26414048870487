const links1win = [
    {
        // "name": "https://bit.ly/-MiniGames",
        // "name": "https://1wxxlb.com/v3/aggressive-casino?p=a3rc&sub1=Aputips",
        "name":"https://1waovx.life/?open=register&p=3alu",
"instagram": "https://www.instagram.com/clubminigames/"
        
    },
]

export default links1win
