import './css/App.css';
import { Route ,Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SocialPage from './pages/SocialPage';

function App() {
  return (
    <>
    <Routes >
      <Route path ="*" element={<HomePage/>}/>
      <Route path ="/social" element={<SocialPage/>}/>
    </Routes>
  </>
  );
}

export default App;
